export default function PrivacyPolicy() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="pixel-font">Gizmoat</h1>
      </header>

      <main className="App-main">
        <div>
          <br />
          <p>
            This privacy policy ("policy") will help you understand how
            Experience Holding B.V. (Trading as Gizmoat studios) ("us", "we",
            "our") uses and protects your data when you play Gizmoat ("app ",
            "service"). We reserve the right to change this policy at any given
            time, of which you will be promptly updated. If you want to make
            sure that you are up to date with the latest changes, we advise you
            to frequently visit this page.
          </p>
          <br />
          <h2>What User Data We Collect</h2>
          <br />
          <p>
            We collect no personal data as a result of you downloading Gizmoat
            or playing the game. We do not store your gameplay, game behaviour,
            metrics or personal information at any time. Gizmoat studios stores
            all gameplay progress data locally on your device for the purposes
            of retaining your game progress only. This information is never
            shared with any third party, or transmitted online in any way.
          </p>
          <br />
          <h2>Our Cookie Policy</h2>
          <br />
          <p>
            Gizmoat studios contains no "cookies" or tracking information of any
            kind.
          </p>
          <br />
          <h2>Links to Other Websites</h2>
          <br />
          <p>
            Gizmoat studios may contain links to external websites or platforms
            (IE Discord). Experience Holding B.V. is not held responsible for
            your data and privacy protection should you choose to visit
            platforms outside of Gizmoat app. Visiting those websites is not
            governed by this privacy policy agreement. Make sure to read the
            privacy policy documentation of the website you go to from our
            website. Gizmoat studios will not lease, sell or distribute your
            personal information to any third parties for any reason at any
            time. We will also never send you promotional materials of any kind
            as a result of downloading this app or playing this game.
          </p>
        </div>
      </main>

      <footer className="App-footer">
        <a href="/" className="footer-link">
          Home
        </a>
        <span> | </span>
        <a href="/terms-and-conditions" className="footer-link">
          Terms and Conditions
        </a>
      </footer>
    </div>
  );
}
