import gizmoatImage from "../assets/gizmoat.png";

export default function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="pixel-font">Gizmoat</h1>
      </header>

      <main className="App-main with-background">
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "flex-end",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={gizmoatImage}
            alt="Main character Gizmoat"
            className="main-character-image"
          />
          <p className="game-description">
            Gizmoat is an exciting adventure game where you hop around, dodge
            obstacles, and try to achieve the highest score possible. But
            beware! A mysterious cloud is always chasing you. How long can you
            survive?
          </p>
        </div>
      </main>

      <footer className="App-footer">
        <a href="/privacy-policy" className="footer-link">
          Privacy Statement
        </a>
        <span> | </span>
        <a href={`mailto:contact@gizmoatstudios.com`} className="footer-link">
          contact@gizmoatstudios.com
        </a>
      </footer>
    </div>
  );
}
